import HomeTemplate from 'templates/HomeTemplate'
import { ExtractGetterProps } from 'shared-definitions/util-types'
import { getHomePageProps } from 'props-getters/home'
import PageContainer from 'shared-components/service/PageContainer'
import Layout from 'components/Layout'

const DealsPage: React.VFC<ExtractGetterProps<typeof getHomePageProps>> = ({
  page,
  common,
  preview,
  ts,
}) => (
  <PageContainer layoutProps={{}} layout={Layout} props={{ page, common, ts, preview }}>
    <HomeTemplate page={page} />
  </PageContainer>
)

export const getStaticProps = getHomePageProps()

export default DealsPage
